import { Button, Form, Input, Modal, Typography } from 'antd/lib'
import { useForm } from 'antd/lib/form/Form'
import Link from 'antd/lib/typography/Link'
import { DefaultResponseDTO, DeviceDTO, LicenseDeviceDTO } from 'api/api_code'
import { DeviceDetailsEndpoints } from 'api/axios/axiosAPIGroups'
import { AxiosError } from 'axios'
import { useHTTPRequestHandler } from 'hooks/useHTTPRequestHandler'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { IconType, NotificationModal } from '../NotificationModal'
import styles from './LicensingModal.module.css'
import { isProBroadcastSelector, useCameraStore } from 'stores/camera.store'
import { errorsOptions } from './helpers/licenseErrorsHandler'

const { Text } = Typography
export interface LicensingModalProps {
  modalState: boolean
  confirmTitleText: string
  explanationText: string
  confirmGuideText?: string
  inputPlaceholder?: string
  cancelText: string
  confirmText: string
  device_uuid: string
  closeCallback: () => void
}

const LicensingModal = ({
  modalState,
  confirmTitleText,
  explanationText,
  confirmGuideText,
  inputPlaceholder,
  cancelText,
  confirmText,
  closeCallback,
  device_uuid,
}: LicensingModalProps) => {
  const router = useRouter()
  const { t } = useTranslation('cameras')
  const [license, setLicense] = useState<LicenseDeviceDTO>({ ticket_id: '' })
  const [openModalOk, setOpenModalOk] = useState(false)
  const [openModalKo, setOpenModalKo] = useState(false)
  const [error, setError] = useState<{ message?: string; hasError: boolean }>({
    message: '',
    hasError: false,
  })
  const [form] = useForm()

  const isProBroadcast = useCameraStore(isProBroadcastSelector)

  const { requestHandler } = useHTTPRequestHandler()
  const onSkip = () => {
    setLicense({ ticket_id: '' })
    setError({ hasError: false })
    form.resetFields()
    closeCallback()
  }
  const errorsHandler = (status: number, code: number) => {
    if (!errorsOptions[status]?.[code]) {
      setOpenModalKo(true)
      return
    }
    setError(errorsOptions[status]?.[code])
  }
  const licenseDevice = () => {
    requestHandler({
      requestPromise: DeviceDetailsEndpoints.licenseDeviceDevicesDeviceUuidLicensePost(
        device_uuid as string,
        license
      ),
      onOkCallback: (device: DeviceDTO) => {
        useCameraStore.setState(device)
        setOpenModalOk(true)
      },
      onKo: {
        onKoCallback: (errorMessage: AxiosError) => {
          const requestStatus = errorMessage.response.status
          const internalCode = (errorMessage.response.data as DefaultResponseDTO).code
          errorsHandler(requestStatus, internalCode)
        },
        overrideCallback: true,
      },
    })
  }

  const onLicensedOK = () => {
    const device_uuid = useCameraStore.getState().uuid
    isProBroadcast
      ? router.push({
          pathname: `/camera/${device_uuid}/details`,
        })
      : router.push({
          pathname: '/camera/[device_uuid]/settings',
          query: {
            device_uuid: device_uuid,
            previous: `/camera/${device_uuid}/details`,
          },
        })
  }

  return (
    <Modal
      className={styles.modal}
      open={modalState}
      onCancel={onSkip}
      centered
      keyboard={true}
      width={438}
      footer={null}
      closeIcon={cancelText}
      closable={true}
    >
      <div className={styles.modalMessage}>
        <h2 className="bold">{confirmTitleText}</h2>
        <p className="mb-2 modalMessage">{explanationText}</p>
        <Form form={form}>
          <Form.Item
            className={styles.input_wrapper}
            name={'license'}
            rules={[
              {
                required: true,
                pattern: new RegExp(/^[A-Z0-9]{5}(?:-[A-Z0-9]{5}){4}$/),
                message: t('licensingModal.errors.licenseID', {
                  pattern: '1A2B3-C4D5E-6F7G8-H9I0J-K1L2M',
                }),
              },
            ]}
          >
            <Input
              className={styles.input}
              placeholder={inputPlaceholder}
              onChange={(e) => {
                e.target.value.match(/^[A-Z0-9]{5}(?:-[A-Z0-9]{5}){4}$/)
                  ? setLicense({ ticket_id: e.target.value })
                  : setLicense({ ticket_id: '' })
              }}
            />
            {/* <MaskedInput
              placeholder="ticket ID"
              mask={'1A2B3-C4D5E-6F7G8-H9I0J-K1L2M'}
              onChange={(e) => setLicense(e.target.value)}
            /> */}
          </Form.Item>
        </Form>

        {error?.hasError && (
          <Text className={styles.error} type="danger">
            {error.message}
          </Text>
        )}
        {confirmGuideText && <p>{confirmGuideText}</p>}
        <Button
          style={
            error ? { width: '211px', marginTop: '9px' } : { width: '211px', marginTop: '27px' }
          }
          size="small"
          type="ghost"
          aria-label="cancel"
          onClick={licenseDevice}
          disabled={!license?.ticket_id}
        >
          {confirmText}
        </Button>
      </div>
      <NotificationModal
        titleIconType={IconType.OK}
        title={t('notificationModal.titleOk')}
        body={<p>{t('notificationModal.explanationOk')}</p>}
        open={openModalOk}
        onOk={() => {
          router.push(
            {
              pathname: '/camera/[device_uuid]/settings',
              query: { device_uuid: device_uuid, previous: `/camera/${device_uuid}/details` },
            },
            `/camera/${device_uuid}/settings`
          )
        }}
        width={438}
        centered
        onCancel={() => setOpenModalOk(false)}
        footer={null}
        skippable={true}
        footerBtns={[
          <Button
            style={{ width: '332px' }}
            key={0}
            type="ghost"
            aria-label="Successful licensing message"
            onClick={onLicensedOK}
          >
            {t('notificationModal.confirmOk', {
              location: isProBroadcast ? 'details' : 'configuration',
            })}
          </Button>,
        ]}
      />
      <NotificationModal
        titleIconType={IconType.KO}
        title={t('notificationModal.titleKo')}
        body={
          <p>
            {t('notificationModal.explanationKo')}
            <Link href="mailto:support@automatic.tv">{t('supportEmail')}</Link>.
          </p>
        }
        open={openModalKo}
        onOk={() => {
          setOpenModalKo(false)
        }}
        width={438}
        centered
        onCancel={() => setOpenModalKo(false)}
        footer={null}
        footerBtns={[
          <Button
            key={0}
            type="ghost"
            aria-label="Unsuccessful licensing message"
            onClick={() => {
              setOpenModalKo(false)
            }}
          >
            {t('notificationModal.confirmKo')}
          </Button>,
        ]}
      />
    </Modal>
  )
}

export { LicensingModal }
