import { Button, Spin, Tooltip, Typography } from 'antd/lib'
import styles from './CameraList.module.css'
import { Icon } from 'components/UI/Icon/Icon'
import { IconNames } from 'components/UI/Icon/icons'
import { DeviceStatusEnum } from 'api/api_code'
import LoadingOutlined from '@ant-design/icons/LoadingOutlined'
import { Translate } from 'next-translate'
import { memo } from 'react'
import clsx from 'clsx'

const { Text } = Typography
export enum FirmwareStatus {
  INSTALL = 'INSTALL',
  INSTALLING = 'INSTALLING',
  UPGRADE = 'UPGRADE',
  UPGRADING = 'UPGRADING',
  LATEST = 'LATEST',
  OFFLINE = 'OFFLINE',
  NONE = 'NONE',
}
interface FirmwareFieldProps {
  version: string
  newVersion: string
  action: (event) => void
  firmwareStatus: FirmwareStatus
  deviceStatus: DeviceStatusEnum
  translate: Translate
  disable?: boolean
  noTextStyle?: boolean
  actionInProgress?: boolean
}
const FirmwareField = memo(
  ({
    version,
    newVersion,
    action,
    firmwareStatus,
    deviceStatus,
    translate,
    disable = false,
    noTextStyle = false,
    actionInProgress = false,
  }: FirmwareFieldProps) => {
    const isCameraUnavailable =
      firmwareStatus === FirmwareStatus.INSTALLING || firmwareStatus === FirmwareStatus.UPGRADING

    const firmwareActionAvailable =
      firmwareStatus === FirmwareStatus.INSTALL || firmwareStatus === FirmwareStatus.UPGRADE

    const noFirmwareInstalled = firmwareStatus === FirmwareStatus.INSTALL

    const firmwareButtonProps = {
      disabled: isCameraUnavailable || disable || actionInProgress,
      className: styles.upgrade,
    }
    const transformVersion = (version) => {
      return version?.includes('commit') ? version?.split('-')[0] : version
    }
    const simplifiedVersion = transformVersion(version)

    const buttonText = {
      [FirmwareStatus.UPGRADE]: translate('upgradeVersion', {
        version: transformVersion(newVersion),
      }),
      [FirmwareStatus.UPGRADING]: translate('upgradingVersion', {
        version: transformVersion(newVersion),
      }),
      [FirmwareStatus.INSTALL]: translate('installVersion', {
        version: transformVersion(newVersion),
      }),
      [FirmwareStatus.INSTALLING]: translate('installingVersion', {
        version: transformVersion(newVersion),
      }),
    }

    const setTextStyles = (status: DeviceStatusEnum) =>
      clsx({
        [styles.soft]: status === DeviceStatusEnum.Offline,
        [styles.velvet]: status === DeviceStatusEnum.Recording,
      })
    return (
      <div className={styles.upgrade}>
        {firmwareStatus !== FirmwareStatus.INSTALL &&
          (version?.includes('commit') ? (
            <Tooltip title={`v.${version}`} className={styles.tooltipHidden}>
              <Text
                data-testid="camera_firmware"
                className={!noTextStyle && setTextStyles(deviceStatus)}
              >
                {!actionInProgress && `v.${simplifiedVersion}`}
              </Text>
            </Tooltip>
          ) : (
            <Text
              data-testid="camera_firmware"
              className={!noTextStyle && setTextStyles(deviceStatus)}
            >
              {!noFirmwareInstalled && `v.${simplifiedVersion}`}
            </Text>
          ))}
        {firmwareStatus !== FirmwareStatus.LATEST && newVersion && (
          <Tooltip title={`v.${newVersion}`} className={styles.tooltipHidden}>
            <Button type="link" {...firmwareButtonProps} {...(!disable && { onClick: action })}>
              {buttonText[firmwareStatus]}
              {firmwareActionAvailable && (
                <Icon component={IconNames.DOWNLOAD} size={24} className="ml-1" />
              )}
              {(isCameraUnavailable || actionInProgress) && (
                <Spin className="ml-1" indicator={<LoadingOutlined size={24} />} />
              )}
            </Button>
          </Tooltip>
        )}
      </div>
    )
  }
)
FirmwareField.displayName = 'FirmwareField'
export default FirmwareField
