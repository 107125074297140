import { Form, Input } from 'antd/lib'
import { Option, OptionDetails } from 'api/api_code'
import { OrganizationsEndpoints } from 'api/axios/axiosAPIGroups'
import { Icon } from 'components/UI/Icon/Icon'
import { IconNames } from 'components/UI/Icon/icons'
import { Select } from 'components/UI/Select/Select'
import { useHTTPRequestHandler } from 'hooks/useHTTPRequestHandler'
import useTranslation from 'next-translate/useTranslation'
import { ChangeEvent, useEffect, useState } from 'react'
import { usePairingFormHandler } from '../hooks/usePairingFormHandler'
import styles from './CameraPairingForm.module.css'
import CameraPairingFormFooter from './CameraPairingFormFooter'

const CameraPairingForm = () => {
  const { t } = useTranslation('cameras')
  const { t: tCommon } = useTranslation('common')
  const [organizations, setOrganizations] = useState<Option[]>([])
  const { requestHandler } = useHTTPRequestHandler()
  const {
    form,
    cameraType,
    pairingData,
    serialStatus,
    inputHandler,
    setPairingData,
    setSerialStatus,
  } = usePairingFormHandler()

  useEffect(() => {
    requestHandler({
      requestPromise: OrganizationsEndpoints.getOrganizationsOrganizationsGet(),
      onOkCallback: (userOrgs: Record<string, OptionDetails>) => {
        setOrganizations(userOrgs.organizations.opts)
        if (userOrgs.organizations.opts.length === 1) {
          setPairingData((prevPairing) => ({
            ...prevPairing,
            organization_uuid: userOrgs.organizations.opts[0].value,
          }))
        }
      },
    })
  }, [])

  useEffect(() => {
    form.resetFields()
  }, [organizations])

  useEffect(() => {
    if (!serialStatus) return
    form.setFields(serialStatus)
  }, [serialStatus])

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark={false}
      className={cameraType === 'ONE' ? styles.formGrid : styles.form}
      initialValues={{
        name: pairingData.name,
        serial: pairingData.serial_number_producer,
        organization:
          organizations.length === 1
            ? organizations[0].display_name
            : pairingData.organization_uuid,
      }}
    >
      <Form.Item
        label={t('pairNewCameraModal.cameraName.label')}
        name="camera-pairing-name"
        className={cameraType === 'ONE' ? styles.formItemNoMargin : styles.formItem}
        rules={[
          {
            required: true,
            message: tCommon('errors.inputMessage', {
              fieldName: t('pairNewCameraModal.cameraName.label'),
            }),
          },
          {
            max: 50,
            message: tCommon('errors.lengthMessage', {
              fieldName: t('pairNewCameraModal.cameraName.label'),
            }),
          },
        ]}
      >
        <Input
          id={'camera-pairing-name'}
          placeholder={t('pairNewCameraModal.cameraName.placeholder')}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            inputHandler({ dataKey: 'name', inputEl: e })
          }
          maxLength={51}
        />
      </Form.Item>
      <Form.Item
        label={t('pairNewCameraModal.serialNumber.label')}
        name="serial"
        tooltip={{
          title:
            'Enter the Service Tag of the server. You can find it on a sticker attached to the top side of the server',
          icon: <Icon component={IconNames.INFO} size={14} />,
        }}
        className={cameraType === 'ONE' ? styles.formItemNoMargin : styles.formItem}
        rules={[
          {
            pattern: new RegExp(/[A-Z0-9]+/g),
            len: 7,
            max: 7,
            message: t('pairNewCameraModal.serialNumber.ruleError'),
          },
        ]}
      >
        <Input
          placeholder={t('pairNewCameraModal.serialNumber.placeholder')}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setSerialStatus([])
            inputHandler({ dataKey: 'serial_number_producer', inputEl: e })
          }}
          maxLength={7}
          className={styles.inputIcon}
        />
      </Form.Item>
      <Form.Item
        label={t('pairNewCameraModal.organization.label')}
        name="organization"
        className={cameraType === 'ONE' ? styles.formItemNoMargin : styles.formItem}
      >
        {organizations.length === 1 ? (
          <Input
            placeholder={organizations[0].display_name}
            value={organizations[0].display_name}
            disabled={true}
            suffix={<Icon component={IconNames.LOCK} size={24} />}
            className={styles.inputIcon}
          />
        ) : (
          <Select
            placeholder={t('pairNewCameraModal.organization.placeholder')}
            options={organizations.map((org) => ({
              value: org.value,
              label: org.display_name,
            }))}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              inputHandler({ dataKey: 'organization_uuid', inputEl: e })
            }
          />
        )}
      </Form.Item>
      {cameraType === 'ONE' && (
        <Form.Item
          label="Server serial number"
          tooltip={{
            title:
              'Enter the Service tag of the server. You can find it on a sticket attached to the top side of th server.',
            icon: <Icon component={IconNames.INFO} size={14} />,
          }}
          className={cameraType === 'ONE' ? styles.formItemNoMargin : styles.formItem}
        >
          {/* TODO: Change server serial number */}
          <Input
            placeholder="Enter here the server's serial number"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              inputHandler({ dataKey: 'serial_number_producer', inputEl: e })
            }
          />
        </Form.Item>
      )}
    </Form>
  )
}

CameraPairingForm.Footer = CameraPairingFormFooter

export { CameraPairingForm }
