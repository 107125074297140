import { Button, Space } from 'antd/lib'
import useTranslation from 'next-translate/useTranslation'
import { useEffect, useRef } from 'react'
import { usePairing } from '../hooks/usePairing'
import styles from './SoftwareInstallation.module.css'

const SoftwareInstallation = () => {
  const filler = useRef(null)
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const { t } = useTranslation('cameras')
  const { deploySoftwareInstallation } = usePairing()

  const animationEndHandler = (ev) => {
    if ((ev.target as HTMLElement).id === 'confirm') {
      deploySoftwareInstallation()
    }
  }

  useEffect(() => {
    if (!buttonRef.current) return

    buttonRef.current.addEventListener('animationend', animationEndHandler)
    return () => {
      buttonRef.current?.removeEventListener('animationend', animationEndHandler)
    }
  }, [])

  return (
    <div className={styles.confirmInstallText}>
      <p className="body-large semibold mt-3 mb-2">{t('softwareInstallation.confirmation')} </p>
      <p className="mb-3">{t('softwareInstallation.processDescription')}</p>
      <Space size={'middle'} className="mt-2">
        <div className={styles.confirmContainer}>
          <div ref={filler} />
          <Button
            ref={buttonRef}
            id="confirm"
            type="ghost"
            aria-label="confirm"
            className={styles.confirmButton}
          >
            {t('softwareInstallation.startInstallButton')}
          </Button>
        </div>
      </Space>
    </div>
  )
}

export { SoftwareInstallation }
