import { FieldData } from 'rc-field-form/lib/interface'
import { DeviceRegisterDTO } from 'api/api_code'
import { GenericModal } from 'components/UI/Modals/Generic/Generic'
import { LicensingModal } from 'components/UI/Modals/LicensingModal/LicensingModal'
import { PairingStep, pairingSteps, PAIRING_STEPS } from 'core/pairing/pairing'
import useTranslation from 'next-translate/useTranslation'
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useState,
  useRef,
  MutableRefObject,
} from 'react'

export type PairingContextType = {
  currentStep: PairingStep
  setCurrentStep: Dispatch<SetStateAction<PairingStep>>
  cameraType: string
  setCameraType: Dispatch<SetStateAction<string>>
  cameraList: string[]
  setCameraList: Dispatch<SetStateAction<string[]>>
  pairingModalState: boolean
  setPairingModalState: Dispatch<SetStateAction<boolean>>
  pairingData: PairingDataDTO
  setPairingData: Dispatch<SetStateAction<PairingDataDTO>>
  serialStatus: FieldData[]
  setSerialStatus: Dispatch<SetStateAction<FieldData[]>>
  softwareInstallationState: string
  pairedDeviceUUID: MutableRefObject<string>
  setSoftwareInstallationState: Dispatch<SetStateAction<string>>
  openLicensingModal: boolean
  setOpenLicensingModal: Dispatch<SetStateAction<boolean>>
}

interface PairingContextProviderProps {
  children: ReactNode
}

export const PairingContext = createContext<PairingContextType>({
  currentStep: null,
  setCurrentStep: () => ({}),
  cameraType: 'ACE',
  setCameraType: () => ({}),
  cameraList: [],
  setCameraList: () => ({}),
  pairingModalState: false,
  setPairingModalState: () => ({}),
  pairingData: null,
  setPairingData: () => ({}),
  serialStatus: [],
  setSerialStatus: () => ({}),
  pairedDeviceUUID: null,
  softwareInstallationState: '',
  setSoftwareInstallationState: () => ({}),
  openLicensingModal: false,
  setOpenLicensingModal: () => ({}),
})

interface PairingDataDTO extends DeviceRegisterDTO {
  organization_uuid: string
}

export function PairingContextProvider({ children }: PairingContextProviderProps) {
  const [cameraList, setCameraList] = useState<string[]>([])
  const [currentStep, setCurrentStep] = useState<PairingStep>(pairingSteps[1])
  const [cameraType, setCameraType] = useState<string>()
  const [pairingModalState, setPairingModalState] = useState<boolean>(false)
  const [pairingData, setPairingData] = useState<PairingDataDTO>({
    serial_number_producer: null,
    organization_uuid: null,
    name: null,
  })
  const [serialStatus, setSerialStatus] = useState<FieldData[]>([])
  const pairedDeviceUUID = useRef<string>(null)
  const [softwareInstallationState, setSoftwareInstallationState] = useState<string>('')
  const [openLicensingModal, setOpenLicensingModal] = useState(false)
  const { t } = useTranslation('cameras')

  return (
    <PairingContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        cameraType,
        setCameraType,
        cameraList,
        setCameraList,
        pairingModalState,
        setPairingModalState,
        pairingData,
        setPairingData,
        serialStatus,
        setSerialStatus,
        pairedDeviceUUID,
        softwareInstallationState,
        setSoftwareInstallationState,
        openLicensingModal,
        setOpenLicensingModal,
      }}
    >
      <GenericModal
        open={pairingModalState}
        closable={true}
        title={currentStep.title}
        centered={true}
        footer={currentStep.footer ?? null}
        width={null}
        onCancel={() => {
          setCurrentStep(pairingSteps[1])
          setPairingModalState(false)
        }}
      >
        {currentStep.component}
      </GenericModal>
      <LicensingModal
        modalState={openLicensingModal || currentStep.index === PAIRING_STEPS.LICENSING}
        confirmTitleText={t('licensingModal.title')}
        explanationText={t('licensingModal.explanation')}
        inputPlaceholder={t('licensingModal.placeholder')}
        cancelText={t('licensingModal.cancel')}
        confirmText={t('licensingModal.confirm')}
        device_uuid={pairedDeviceUUID.current}
        closeCallback={() => {
          setCurrentStep(pairingSteps[1])
          setOpenLicensingModal(false)
        }}
      />
      {children}
    </PairingContext.Provider>
  )
}
