import { Form } from 'antd/lib'
import { usePairingContext } from 'hooks/usePairingContext'
import { ChangeEvent } from 'react'

export function usePairingFormHandler() {
  const [form] = Form.useForm()
  const { setPairingData, ...pairing } = usePairingContext()

  const onDataChange = (dataKey: string, value: string) => {
    setPairingData((prevPairing) => ({
      ...prevPairing,
      [dataKey]: value,
    }))
  }

  const inputHandler = ({
    dataKey,
    inputEl,
  }: {
    dataKey: string
    inputEl: ChangeEvent<HTMLInputElement>
  }) => {
    const inputValue = inputEl?.target ? inputEl.target.value : inputEl
    onDataChange(dataKey, String(inputValue))
  }

  return {
    form,
    inputHandler,
    setPairingData,
    ...pairing,
  }
}
