import { DeployActionEnum, SoftwareNameEnum } from 'api/api_code'
import { FirmwareStatus } from '../components/FirmwareField'

const getFirmwareStatus = ({
  device,
  softwareAvailable,
}): { version: string; status: FirmwareStatus } => {
  const ATVVersionInstalled = device.software_installed?.find(
    (software) => software?.version?.software.name === SoftwareNameEnum.Automatictv
  )
  const ATVVersionInstalling = device.software_installing?.find(
    (software) =>
      software?.action === DeployActionEnum.Install &&
      software?.version?.software?.name === SoftwareNameEnum.Automatictv
  )

  const ATVVersionUpgrading = device.software_installing?.find(
    (software) =>
      software?.action === DeployActionEnum.Upgrade &&
      software.version?.software?.name === SoftwareNameEnum.Automatictv
  )
  if (!ATVVersionInstalled?.version?.version_number) {
    return {
      version:
        ATVVersionInstalling || softwareAvailable.version.version_number
          ? softwareAvailable.version.version_number
          : '-',
      status: ATVVersionInstalling ? FirmwareStatus.INSTALLING : FirmwareStatus.INSTALL,
    }
  }

  if (
    ATVVersionInstalled &&
    softwareAvailable &&
    ATVVersionInstalled.version?.version_number !== softwareAvailable.version?.version_number
  ) {
    return {
      version: ATVVersionInstalled.version.version_number,
      status: ATVVersionUpgrading ? FirmwareStatus.UPGRADING : FirmwareStatus.UPGRADE,
    }
  }
  if (
    ATVVersionInstalled &&
    (ATVVersionInstalled.version?.version_number === softwareAvailable?.version?.version_number ||
      !softwareAvailable)
  ) {
    return {
      version: ATVVersionInstalled.version.version_number,
      status: FirmwareStatus.LATEST,
    }
  }
}
export default getFirmwareStatus
