import { useState } from 'react'
import { FirmwareStatus } from '../components/FirmwareField'
import { DeviceDetailsEndpoints } from 'api/axios/axiosAPIGroups'
import { useHTTPRequestHandler } from 'hooks/useHTTPRequestHandler'
import { usePairingContext } from 'hooks/usePairingContext'
import { PAIRING_STEPS, pairingSteps } from 'core/pairing/pairing'
import { useLoading } from 'hooks/useLoading'

type CameraFirmwareAction = {
  device: string
  inProgress: boolean
}
type ActionInProgressState = {
  [uuid: string]: boolean
}
const useCameraFirmwareActions = () => {
  const [firmwareActionInProgress, setFirmwareActionInProgress] = useState<ActionInProgressState>(
    {}
  )
  const { requestHandler } = useHTTPRequestHandler()
  const { setPairingModalState, setCurrentStep, pairedDeviceUUID } = usePairingContext()
  const { setLoading } = useLoading()
  const handleFirmwareActionInProgress = ({ device, inProgress }: CameraFirmwareAction) => {
    setFirmwareActionInProgress((prevFirmwareAction) => {
      return { ...prevFirmwareAction, [device]: inProgress }
    })
  }
  const resetFirmwareActions = () => {
    setFirmwareActionInProgress({})
  }
  const initCameraFirmwareAction = {
    [FirmwareStatus.UPGRADE]: ({ cameraUUID }) => {
      requestHandler({
        requestPromise: DeviceDetailsEndpoints.deployAutomatictvDevicesDeviceUuidDeployPost(
          cameraUUID,
          true
        ),
        onOkCallback: () => {
          setLoading(false)
          setFirmwareActionInProgress((prevFirmwareAction) => {
            return { ...prevFirmwareAction, [cameraUUID]: true }
          })
        },
        onKo: {
          onKoCallback: () => {
            setLoading(false)
            setFirmwareActionInProgress((prevFirmwareAction) => {
              return { ...prevFirmwareAction, [cameraUUID]: false }
            })
          },
          overrideCallback: true,
        },
        autoLoading: false,
      })
    },
    [FirmwareStatus.INSTALL]: ({ cameraUUID }) => {
      pairedDeviceUUID.current = cameraUUID
      setCurrentStep(pairingSteps[PAIRING_STEPS.DEPLOY])
      setPairingModalState(true)
    },
  }
  return {
    cameraFirmwareAction: initCameraFirmwareAction,
    firmwareActionInProgress,
    setFirmwareActionInProgress: handleFirmwareActionInProgress,
    resetFirmwareActions,
  }
}
export default useCameraFirmwareActions
