import { Modal, ModalProps } from 'antd/lib'
import React, { ReactNode } from 'react'
import { IconNames } from 'components/UI/Icon/icons'
import { Icon } from 'components/UI/Icon/Icon'
import styles from './Generic.module.css'
import clsx from 'clsx'

interface GenericModal extends ModalProps {
  children: ReactNode
}
const GenericModal: React.FC<GenericModal> = (genericModalProps: GenericModal) => {
  const genericModalStyles = clsx(genericModalProps.className, {
    [styles['ant-modal-body']]: true,
  })
  return (
    <Modal
      {...genericModalProps}
      title={null}
      closeIcon={<Icon component={IconNames.CROSS} size={24} />}
      className={genericModalStyles}
    >
      <h2 className="bold">{genericModalProps.title}</h2>
      {genericModalProps.children}
    </Modal>
  )
}

export { GenericModal }
