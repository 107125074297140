import { Menu } from 'components/Navigation/Menu/Menu'
import styles from './CamerasListLayout.module.css'

interface CamerasListLayoutProps {
  children: React.ReactNode
  title?: string
}

function CamerasListLayout({ children }: CamerasListLayoutProps) {
  return (
    <>
      <Menu />
      <main className={styles.camerasListLayout}>{children}</main>
    </>
  )
}
export default CamerasListLayout
