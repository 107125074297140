import { AuthContext } from 'context/AuthContext'
import { useContext, useEffect } from 'react'
import { useHTTPRequestHandler } from './useHTTPRequestHandler'
import { UsersEndpoints } from '../api/axios/axiosAPIGroups'

export function useAuth() {
  const { user, setUser } = useContext(AuthContext)
  const { requestHandler } = useHTTPRequestHandler()

  useEffect(() => {
    requestHandler({
      requestPromise: UsersEndpoints.userMeUsersMeGet(),
      onOkCallback: (user) => setUser(user),
    })
  }, [])

  return {
    user,
  }
}
