import { CameraLicense } from 'types/global'

export const cameraLicenses = {
  [CameraLicense.PRO_BASIC]: 'ATV Pro Basic',
  [CameraLicense.PRO_ADVANCED]: 'ATV Pro Advanced',
  [CameraLicense.BROADCAST_BASIC]: 'ATV Broadcast Basic',
  [CameraLicense.BROADCAST_ADVANCED]: 'ATV Broadcast Advanced',
  [CameraLicense.ATV_ONE]: 'ATV One',
  [CameraLicense.ATV_ACE]: 'ATV Ace',
}
export const getCameraLicense = (license: string) => {
  return cameraLicenses[license] ?? '-'
}
