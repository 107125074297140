import { OptionsEndpoints } from 'api/axios/axiosAPIGroups'
import { useHTTPRequestHandler } from 'hooks/useHTTPRequestHandler'
import { Icon } from 'components/UI/Icon/Icon'
import { CameraNames } from 'components/UI/Icon/icons'
import { pairingSteps } from 'core/pairing/pairing'
import { usePairingContext } from 'hooks/usePairingContext'
import { useEffect } from 'react'
import styles from './CameraSelector.module.css'

const CameraSelector = () => {
  const { cameraList, setCameraList, setCurrentStep, setCameraType } = usePairingContext()
  const { requestHandler } = useHTTPRequestHandler()

  useEffect(() => {
    requestHandler({
      requestPromise: OptionsEndpoints.getCameraTypesOptionsCameraTypesGet(),
      onOkCallback: (cameraTypes: string[]) => {
        setCameraList(cameraTypes)
      },
    })
  }, [])

  const handleSelectCamera = (camera: string) => {
    setCurrentStep((prevStep) => pairingSteps[prevStep.index + 1])
    setCameraType(camera)
  }

  return (
    <ul className={styles.cameraList}>
      {cameraList.map((camera) => (
        <li key={camera} className={styles.cameraCard} onClick={() => handleSelectCamera(camera)}>
          <Icon component={CameraNames[camera]} size={124} />
          <span>{`ATV ${camera}`}</span>
        </li>
      ))}
    </ul>
  )
}

export { CameraSelector }
