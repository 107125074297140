import { Typography } from 'antd/lib'
import {
  CameraDetailsDTO,
  DeployActionEnum,
  DeviceStatusEnum,
  SoftwareNameEnum,
} from 'api/api_code'
import clsx from 'clsx'
import { Icon } from 'components/UI/Icon/Icon'
import { IconNames } from 'components/UI/Icon/icons'
import { snakeCaseToTitleCase } from 'helpers/utils'
import { usePairingContext } from 'hooks/usePairingContext'
import { useRouter } from 'next/router'
import { Dispatch, memo, SetStateAction } from 'react'
import styles from './CameraList.module.css'

const CameraStatus = memo(
  ({
    camera,
    setOpenLicensingModal,
  }: {
    camera: CameraDetailsDTO
    setOpenLicensingModal: Dispatch<SetStateAction<boolean>>
  }) => {
    const router = useRouter()
    const { pairedDeviceUUID } = usePairingContext()
    const { status, uuid, software_installing } = camera
    const caseUpgrading =
      software_installing?.find(
        (item) => item.version?.software?.name === SoftwareNameEnum.Automatictv
      )?.action === DeployActionEnum.Upgrade
    const caseInstalling =
      software_installing?.find(
        (item) => item.version?.software?.name === SoftwareNameEnum.Automatictv
      )?.action === DeployActionEnum.Install

    const cameraLinkClickHandler = () => {
      if (status === DeviceStatusEnum.Unlicensed) {
        pairedDeviceUUID.current = uuid
        setOpenLicensingModal(true)
      } else if (status === DeviceStatusEnum.NotConfigured) {
        router.push(
          {
            pathname: '/camera/[device_uuid]/settings',
            query: { device_uuid: uuid, previous: `/camera/${uuid}/details` },
          },
          `/camera/${uuid}/settings`
        )
      }
    }

    if (status === DeviceStatusEnum.Unlicensed || status === DeviceStatusEnum.NotConfigured) {
      return (
        <Typography.Link
          data-testid="camera_status"
          onClick={(event) => {
            cameraLinkClickHandler()
            event.stopPropagation()
          }}
          disabled={caseUpgrading || caseInstalling}
        >
          {snakeCaseToTitleCase(camera)}
        </Typography.Link>
      )
    }

    const setTextStyles = (status: DeviceStatusEnum) =>
      clsx({
        [styles.soft]: status === DeviceStatusEnum.Offline,
        [styles.velvet]: status === DeviceStatusEnum.Recording,
        [styles.gold]: status === DeviceStatusEnum.Unlicensed,
        [styles.gold]: status === DeviceStatusEnum.NotConfigured,
      })

    return (
      <Typography.Text data-testid="camera_status" className={setTextStyles(status)}>
        {status === DeviceStatusEnum.Recording && (
          <Icon component={IconNames.RECORDING} className="mr-05" />
        )}
        {snakeCaseToTitleCase(camera)}
      </Typography.Text>
    )
  }
)

CameraStatus.displayName = 'CameraStatus'

export { CameraStatus }
